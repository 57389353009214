import React, { useRef, useState, useEffect } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Logo from "./logo";
import { motion } from "framer-motion";
import { useWindowScroll, useWindowSize, useRafState } from "react-use";
import ExpandableMenu from "./expandableMenu";
import PrismicLink from "../prismicLink";

const container = {
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.05,
      ease: "easeOut",
    },
  },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.05,
      ease: "easeOut",
      staggerDirection: -1,
    },
  },
};

const item = {
  hidden: {
    x: 20,
    opacity: 0,
    transitionEnd: { display: "none" },
    transition: {
      ease: "easeOut",
    },
  },
  visible: {
    x: 0,
    opacity: 1,
    display: "block",
    transition: {
      ease: "easeOut",
    },
  },
};

const useDimensions = () => {
  const ref = useRef();
  const [state, setState] = useRafState({});
  const { width } = useWindowSize();

  useEffect(() => {
    if (ref.current) {
      setState(ref.current.getBoundingClientRect());
    }
  }, [width, ref, setState]);

  return [ref, state];
};

const Header = ({ node }) => {
  const [isExpandableOpen, setIsExpandableOpen] = useState(false);
  const toggleExpandableMenu = () => setIsExpandableOpen(!isExpandableOpen);
  const menu = node?.main_navigation || [];

  const [headerBoxRef, { left, width }] = useDimensions();
  const { y } = useWindowScroll();
  const isMobile = width < 992;
  const isVisible = isMobile ? y > 20 : y > 60;

  const [isAnimationCompleted, setIsAnimationCompleted] = useState(false);

  return (
    <motion.header
      className={`header ${isVisible ? "hidden" : "visible"} ${
        isAnimationCompleted ? "menu-closed" : "menu-opened"
      }`}
      role="banner"
      {...(isMobile
        ? {
            initial: "mobileVisible",
            animate: isVisible ? "mobileHidden" : "mobileVisible",
          }
        : { initial: "visible", animate: isVisible ? "hidden" : "visible" })}
    >
      <div className="header__content" ref={headerBoxRef}>
        <div className="header__logo">
          <Link to="/" onClick={() => setIsExpandableOpen(false)}>
            <span className="header__logo-text">Modus</span>
            <Logo />
          </Link>
        </div>
        <div className="header__menu">
          <nav role="navigation">
            <motion.ul variants={container}>
              {menu?.map((menuItem, idx) => (
                <motion.li key={idx} className="item" variants={item}>
                  <PrismicLink
                    link={menuItem?.main_navigation_link}
                    activeClassName="active"
                    partiallyActive={true}
                  >
                    {menuItem?.main_navigation_name?.text}
                  </PrismicLink>
                </motion.li>
              ))}
            </motion.ul>
          </nav>
        </div>
        {left >= 0 && (
          <ExpandableMenu
            leftPosition={left + width}
            menu={menu}
            node={node}
            isExpandableOpen={isExpandableOpen}
            isVisible={isVisible}
            toggleExpandableMenu={toggleExpandableMenu}
            setIsAnimationCompleted={setIsAnimationCompleted}
          />
        )}
      </div>
    </motion.header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
